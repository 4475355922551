@use '../../../common/styles/colors/colors.scss' as colors;
@import '../../../common/styles/typography/typography.scss';

$active-marker: url('../../../static/active_marker.webp');

.category-item {
    flex-shrink: 0;
    width: 100%;
    max-width: 270px;
    border-right: 1px solid #DCDCDC;
    background-color: colors.$lightGrey;
    padding: 10px;
    padding-bottom: calc(30px + 45px);
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 3px 1px black inset;
    }

    &.active {
        position: relative;
        background-color: colors.$disabledGrey;

        span {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 8px);
            display: block;
            width: 109px;
            height: 53px;
            background-image: $active-marker;
            background-size: contain;
            z-index: 4;
        }
    }

    &:first-child {
        border-top-left-radius: 45px;
    }

    &:last-child {
        border-top-right-radius: 45px;
        border-right: none;
    }

    p {
        text-align: center;
        max-width: 256px;
        margin: 0 auto;
    }

    &.active .category-item__img::after {
        content: '';
        position: absolute;
        bottom: 13px;
        left: 50%;
        transform: translateX(-50%);
        width: 161px;
        height: 161px;
        border-radius: 50%;
        background-color: currentColor;
    }
}

.category-item__img {
    position: relative;
    width: 100%;
    height: 256px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;
        z-index: 2;
    }
}