@use '../../common/styles/colors/colors.scss' as colors;
@import '../../common/styles/typography/typography.scss';
@import '../../common/styles/button/button.scss';

.wrapper {
    width: 100%;
    height: 1920px;
    background-color: colors.$red;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 50px 20px 60px;

    & > * {
        width: 100%;
    }
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 47px;
}

.content__image {
    margin: 0 auto;
    width: 334px;
    height: 334px;
    border: 7px solid colors.$white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 149px;
        height: 149px;
        color: colors.$white;
    }
}

.content__title {
    @extend .h3;
    color: colors.$white;
    text-align: center;
}

.controls {
    flex-shrink: 0;
    
    & > * + * {
        margin-top: 30px;
    }
    
    button {
        @extend .button;
        @extend .text-big;
    }

    .retry {
        color: colors.$red;
    }

    .cancel {
        border: 1px solid colors.$white;
        background-color: transparent;
        color: colors.$white;
    }
}


