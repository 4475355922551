*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  -webkit-font-smoothing: antialiased;

  & > * {
    width: 100%;
  }
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

button, input, textarea {
  outline: none;
  font-family: inherit;
  font-size: inherit;
  border: none;
}

button:focus, input:focus, textarea:focus {
  outline: none;
}

a, a:hover, a:visited {
 color: inherit;
 text-decoration: none; 
}

ul {
  list-style-type: none;
}