@use '../../../common/styles/colors/colors.scss' as colors; 
@import '../../../common/styles/typography/typography.scss';

.wrapper {
    & > * + * {
        margin-top: 15px;
    }
    max-height: 1100px;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.option-item {
    min-height: 119px;
    padding: 12px 20px;
    border: 1px solid colors.$darkGrey;
    border-radius: 25px;
    display: flex;
    align-items: center;
    column-gap: 30px;
}

.option-name {
    @extend .text-small;

    font-size: 36px;
    flex-grow: 1;
}

.option-counter {
    flex-shrink: 0;
    display: flex;
    column-gap: 10px;
    width: 333px;

    @extend .text-small;
    font-size: 30px;
    line-height: 95px;
    text-align: center;

    button {
        width: 95px;
        height: 95px;
        border-radius: 15px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: colors.$yellow;
        font-size: 40px;

        &:disabled {
            color: inherit;
            background-color: colors.$lightGrey;
        }
    }

    span {
        flex-grow: 1;
        font-variant-numeric: tabular-nums;
    }
}
