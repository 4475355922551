@font-face {
    font-family: 'Mont-Regular';
    src: url('../../../fonts/Mont-Regular.woff2') format('woff2');
    font-display: swap;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Mont-Semibold';
    src: url('../../../fonts/Mont-SemiBold.woff2') format('woff2');
    font-display: swap;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Mont-Light';
    src: url('../../../fonts/Mont-Light.woff2') format('woff2');
    font-display: swap;
    text-rendering: optimizeLegibility;
}