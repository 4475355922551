@use '../../../common/styles/colors/colors.scss' as colors;
@import '../../../common/styles/typography/typography.scss';

.product-card {
    font-family: 'Mont-Regular';
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 35px;
    border: 1px solid colors.$outlineGrey;
    padding: 22px 17px 23px;
    color: colors.$black;
    text-align: center;
    cursor: pointer;

    &:hover, &:active {
        box-shadow: 0 0 3px 1px black inset;
    }

    & > * {
        width: 100%;
    }

    .product-card__img-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 296px;
        height: 296px;
        
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    h5 {
        @extend .text-small;
        margin-top: 42px;
        flex-grow: 1;
    }

    p {
        font-family: 'Mont-Semibold';
        font-size: 40px;
        font-weight: 700;
        line-height: 111%;
        margin-top: 1px;

        span {
            font-size: 64px;
            line-height: 111%;
        }
    }
}