@use '../colors/colors.scss' as colors;

.button {
    width: 100%;
    height: 163px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    padding: 0 43px;
    transition: 0.3s;
    flex-shrink: 0;
    cursor: pointer;

    & > * + * {
        margin-left: auto;
    }

    &:hover {
        box-shadow: 0 0 3px 2px rgba(black, 0.9) inset;
    }

    &:active {
        opacity: 0.75;
    }

    &:disabled {
        background-color: colors.$disabledGrey;
        color: colors.$outlineGrey;
        opacity: 1;
        box-shadow: none;
    }
}