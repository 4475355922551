@property --bgc {
  syntax: '<color>';
  inherits: false;
  initial-value: white;
}

.wrapper {
    position: relative;
    width: 100%;
    height: 1920px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: var(--bgc);
}

