$black: #000;
$white: #FFF;

$darkGrey: #D3D3D3;
$outlineGrey: #D8D8D8;
$lightGrey: #F3F3F3;
$disabledGrey: #FAFAFA;
$overlay: #3E3E3E;

$gold: #E8C40F;
$yellow: #F5D009;
$red: #F03B3B;
$orange: #F58809;

$coffee: #EFCCB9;
$coffeeLight: #FFE7DB;

$tea: #C9EA94;
$teaLight: #DEF8B7;

$coctail: #F9ECD2;
$coctailLight: #FFF7E8;

$juice: #FFE665;
$juiceLight: #FFEF9A;
