@use '../../common/styles/colors/colors.scss' as colors;
@import '../../common/styles/typography/typography.scss';
@import '../../common/styles/button/button.scss';

.wrapper {
    width: 100%;
    height: 1920px;
    background-color: colors.$white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 50px 20px 60px;

    & > * {
        width: 100%;
    }
}

.button-back {
    width: 391px;
    height: 187px;
    background-color: colors.$disabledGrey;
    border-radius: 25px;
    color: colors.$darkGrey;
    border: 1px solid colors.$darkGrey;
    cursor: pointer;

    svg {
        width: 30px;
        height: 68px;
        color: inherit;
    }

    &:hover, &:active {
        color: colors.$overlay;
        background-color: colors.$lightGrey;
        border-color: colors.$overlay;
    }
}

.content {
    padding-inline: 60px;

    & > * + * {
        margin-top: 30px;
    }
}

.content__img {
    margin: 0 auto;
    width: 238px;
    height: 238px;
    border-radius: 50px;
    background-color: colors.$yellow;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 92px;
        height: 124px;
    }
}

.content__title {
    @extend .h3;
}

.buttons-selection {
    & > * + * {
        margin-top: 20px;
    }

    button {
        @extend .button;
        @extend .text-big;
    }
}

.button-accented {
    background-color: colors.$yellow;
}
