@use '../../common/styles/colors/colors.scss' as colors;
@import '../../common/styles/typography/typography.scss';
@import '../../common/styles/button/button.scss';

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 47px;
}

.content__image {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 346px;
        height: 326px;
        color: colors.$white;
    }
}

.content__title {
    @extend .h3;
    color: colors.$white;
    text-align: center;
}

.controls {
    position: absolute;
    padding: 0 20px;
    bottom: 135px;
    width: 100%;

}

.cancel {
    @extend .button;
    @extend .text-big;
    color: colors.$orange;
}
