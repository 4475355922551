@use '../../common/styles/colors/colors.scss' as colors;
@import '../../common/styles/typography/typography.scss';

.wrapper {
    width: 100%;
    height: 1920px;
    position: relative;
    overflow: hidden;
    background-color: currentColor;

    &.theme-1 {
        color: colors.$coffee;

        .heading-decor {
            color: colors.$coffeeLight;
        }
    }

    &.theme-2 {
        color: colors.$tea;

        .heading-decor {
            color: colors.$teaLight;
        }
    }

    &.theme-3 {
        color: colors.$coctail;
        
        .heading-decor {
            color: colors.$coctailLight;
        }
    }

    &.theme-4 {
        color: colors.$juice;

        .heading-decor {
            color: colors.$juiceLight;
        }
    }

}

.heading {
    position: relative;
    overflow: hidden;
    padding: 80px 20px;
}

.heading-decor {
    position: absolute;
    top: -330px;
    right: 65px;
    width: 450px;
    height: 940px;
}

.categories {
    position: relative;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 4px 5px rgba(0,0,0, 0.09);
}

.products {
    height: 100%;
    background-color: #fff;
    position: relative;
    z-index: 2;
    border-radius: 45px;
    margin-top: -45px;
    padding: 87px 20px 67px;
    filter: drop-shadow(0 -10px 75px rgba(0,0,0, 0.09));

    h2 {
        position: relative;
        z-index: 2;
    }
}

.products__header {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: -42px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        display: block;
        width: 147px;
        height: 147px;
        background-color: currentColor;
        z-index: 1;
    }
}

.products__content-wrapper {
    margin-top: 62px;
    margin-right: -12px; // Stretch to the right for the scroll bar
    height: 1007px;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        display: block;
        width: 5px;
    };

    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F3F3F3;
    }

    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: colors.$yellow;
        border-radius: 5px;
    }
}

.products__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-right: 12px; // Compensate for the parent scrollbar stretch
    width: 1040px;
    
    &::-webkit-scrollbar {
        display: none;
    }
}