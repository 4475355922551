@use '../../common/styles/colors/colors.scss' as colors;
@import '../../common/styles/typography/typography.scss';

.wrapper {
    width: 100%;
    height: 1920px;
    background-color: colors.$white;
    padding: 91px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

$outerSize: 900px;
$circleSize: 876px;
$circleThickness: 5px;
$dotSize: 47px;
$bg: #EAEAEA;
$fg: colors.$yellow;

.progress-group {
    position: relative;
    animation: growProgressBar var(--timerDuration) linear forwards;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-family: 'Mont-Light';
        display: block;
        text-align: center;
    }
}

svg.progress__circle {
    --circumference: calc(#{$circleSize} * 3.14);
    width: $outerSize;
    height: $outerSize;
    transform: rotate(-90deg);
    
    circle.circle__bg {
        fill: transparent;
        stroke: $bg;
        stroke-width: $circleThickness;
    }
    
    circle.circle__fg {
        fill: transparent;
        stroke: $fg;
        stroke-width: $circleThickness;
        stroke-dasharray: var(--circumference);
        stroke-dashoffset: calc(var(--circumference) * ((100 - var(--progress)) / 100));
        transform-origin: center;
        transform: scaleY(-1);
    }
}

.progress__dot {
    width: $circleSize;
    height: $circleSize;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate( calc(var(--progress) * -3.6deg));

    &::after {
        content: '';
        width: $dotSize;
        height: $dotSize;
        border-radius: 50%;
        background-color: colors.$yellow;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(-50% + $circleThickness / 2));
    }
}

.progress__display {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.timer {
    color: colors.$black;
    font-size: 260px;
    line-height: 260px;
    font-variant-numeric: tabular-nums;
}

.status {
    position: absolute;
    width: 100%;
    top: 105%;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
    font-size: 40px;
    line-height: 141%;
    color: #a7a7a7;
}

@property --progress {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

@property --timerDuration {
    syntax: '<time>';
    inherits: true;
    initial-value: 0s;
}

@keyframes growProgressBar {
  0%{
    --progress: 0;
  }
  100% {
    --progress: 100;
  }
}