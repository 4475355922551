@use '../../common/styles/colors/colors.scss' as colors;
@import '../../common/styles/typography/typography.scss';
@import '../../common/styles/button/button.scss';

.wrapper {
    position: relative;
    width: 100%;
    height: 1920px;
    overflow: hidden;
    background-color: colors.$yellow;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 50px 20px 60px;

    & > * {
        width: 100%;
    }

    & > button {
        @extend .button;
        @extend .text-big;
        background-color: colors.$white;
        flex-shrink: 0;
    }
}

.content {
    padding: 113px 60px 0;
    padding-inline: 60px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    &__block {
        position: relative;
        width: 100%;
        top: -68px;
    }
}

.content__img {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 433px;
        height: 275px;
    }
}

.content__title {
    @extend .h3;
    position: absolute;
    width: 100%;
    top: calc(100% + 68px);
}
