@use '../../common/styles/colors/colors.scss' as colors;
@import '../../common/styles/typography/typography.scss';
@import '../../common/styles/button/button.scss';

.wrapper {
    width: 100%;
    height: 1920px;
    background-color: colors.$yellow;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 50px 20px 60px;

    & > * {
        width: 100%;
    }
}

.content {
    padding: 113px 60px 0;
    padding-inline: 60px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    & > * + * {
        margin-top: 68px;
    }
}

.content__img {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 433px;
        height: 275px;
    }
}

.content__title {
    @extend .h3;
}

.control-buttons {
    button {
        @extend .button;
        @extend .text-big;
        background-color: colors.$white;
        flex-shrink: 0;
    }

    button.filled {
        background-color: transparent;
        border: 1px solid colors.$black;
        color: colors.$white;
        position: relative;
        overflow: hidden;

        & > * + * {
            margin-left: 0;
        }

        .filled__overlay {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: colors.$black;
            transition: 1s;
        }

        & span {
            position: relative;
        }
    }

    & > * + * {
        margin-top: 20px;        
    }
}
